define("m08-2020/components/_gabellager_masskette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'gabel-lager',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    objektSkalierung: 1,
    hoeheAnzeigen: Ember.computed('foobar', function () {
      return this.get('hoeheAnzeigen');
    }),
    textgroesse: Ember.computed('model.firstObject.{skalierungsfaktor,transformHelper}', function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }),
    hoeheMasskette: 0.5,
    abstandVomBauteil: 0.15,
    abstandVonDerMasslinie: 0.05,
    x: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('x')) / 10;
    }),
    y: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('y')) / 10;
    }),
    z: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('z')) / 10;
    }),
    spaltmass: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'gabellager').get('erweiterteeigenschaften').objectAt(0).get('abstandsmass')) / 10;
    }),
    traegerbreite: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 10;
    }),
    traegerhoehe: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y')) / 10;
    }),
    traegerlaenge: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x')) / 10;
    }),
    auflagerhoehe: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y')) / 10;
    }),
    auflagerlaenge: Ember.computed('model.firstObject.transformHelper', function () {
      return Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('x')) / 10;
    }),
    winkelRadinat: function (grad) {
      return grad * Math.PI / 180;
    },
    gabelhoeheEingetragen: Ember.computed('model.firstObject.hGabellagerEingetragen', function () {
      return this.get('model').objectAt(0).get('hGabellagerEingetragen');
    }),
    gabelMasslinien: Ember.computed('model.firstObject.transformHelper', function () {
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var drehwinkelX;
      var drehwinkelY;
      var drehwinkelZ;
      var x1, y1, z1, x2, y2, z2, ueberstand;

      switch (typ) {
        case 'hoehe':
          x1 = this.get('x') / 2;
          y1 = 0;
          z1 = this.get('traegerbreite') / 2 + this.get('spaltmass') + this.get('z');
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : x1;
          y2 = x3d.get('hGabellagerEingetragen') === false ? this.get('traegerhoehe') * 0.9 : this.get('y');

          if (x3d.get('bauteile').findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x1 = this.get('traegerlaenge') / 2;
            }

            z1 = z1 + this.get('abstandVomBauteil');
            drehwinkelX = this.winkelRadinat(90);
            drehwinkelY = 0;
            drehwinkelZ = -this.winkelRadinat(90);
          } else {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x1 = this.get('traegerlaenge') / 2;
            }

            x1 = x1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = 0;
            drehwinkelZ = -this.winkelRadinat(90);
          }

          x2 = x1;
          z2 = z1;
          break;

        case 'breite1':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z1 = -this.get('spaltmass') - this.get('traegerbreite') / 2;
          z2 = -this.get('z') - this.get('spaltmass') - this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x1 = this.get('traegerlaenge') / 2;
            }

            x1 = x1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = -this.winkelRadinat(90);
          } else {
            y1 = y1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = 0;
          }

          x2 = x1;
          y2 = y1;
          break;

        case 'breite2':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z1 = this.get('z') + this.get('spaltmass') + this.get('traegerbreite') / 2;
          z2 = this.get('spaltmass') + this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x1 = this.get('traegerlaenge') / 2;
            }

            x1 = x1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = -this.winkelRadinat(90);
          } else {
            y1 = y1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = 0;
          }

          x2 = x1;
          y2 = y1;
          break;

        case 'laenge':
          x1 = this.get('x') / 2;
          y1 = -0.5 * this.get('traegerhoehe');
          z1 = this.get('traegerbreite') / 2 + this.get('spaltmass') + this.get('z');
          x2 = -x1;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            z1 = z1 + this.get('hoeheMasskette') + 2 * this.get('abstandVomBauteil');
            drehwinkelX = this.winkelRadinat(90);
            drehwinkelY = 0;
            drehwinkelZ = 0;
          } else {
            y1 = y1 - this.get('hoeheMasskette') - 2 * this.get('abstandVomBauteil');
            drehwinkelX = this.winkelRadinat(180);
            drehwinkelY = 0;
            drehwinkelZ = 0;
          }

          y2 = y1;
          z2 = z1;
          break;

        case 'spaltmass1':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z1 = -this.get('traegerbreite') / 2;
          z2 = -this.get('spaltmass') - this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x1 = this.get('traegerlaenge') / 2;
            }

            x1 = x1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = -this.winkelRadinat(90);
          } else {
            y1 = y1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = 0;
          }

          x2 = x1;
          y2 = y1;
          break;

        case 'spaltmass2':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x1 = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y1 = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z1 = this.get('spaltmass') + this.get('traegerbreite') / 2;
          z2 = this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x1 = this.get('traegerlaenge') / 2;
            }

            x1 = x1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = -this.winkelRadinat(90);
          } else {
            y1 = y1 + this.get('abstandVomBauteil');
            drehwinkelX = 0;
            drehwinkelY = -this.winkelRadinat(90);
            drehwinkelZ = 0;
          }

          x2 = x1;
          y2 = y1;
          break;
      }

      return this.getMassketteKoordinaten({
        x: x1,
        y: y1,
        z: z1
      }, {
        x: x2,
        y: y2,
        z: z2
      }, {}, {
        x: drehwinkelX,
        y: drehwinkelY,
        z: drehwinkelZ
      });
    }),
    gabelTextTranslation: Ember.computed('model.firstObject.transformHelper', function () {
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var x, y, z, ueberstand;

      switch (typ) {
        case 'hoehe':
          x = this.get('x') / 2;
          y = this.get('y') / 2;
          z = this.get('z') + this.get('spaltmass') + this.get('traegerbreite') / 2;
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : x;

          if (x3d.get('bauteile').findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x = this.get('traegerlaenge') / 2;
            }

            z = z + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          } else {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x = this.get('traegerlaenge') / 2;
            }

            x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          }

          break;

        case 'breite1':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z = -this.get('z') / 2 - this.get('spaltmass') - this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x = this.get('traegerlaenge') / 2;
            }

            x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          } else {
            y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          }

          break;

        case 'breite2':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z = +this.get('z') / 2 + this.get('spaltmass') + this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x = this.get('traegerlaenge') / 2;
            }

            x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          } else {
            y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          }

          break;

        case 'laenge':
          x = 0;
          y = -0.5 * this.get('traegerhoehe');
          z = this.get('z') + this.get('spaltmass') + this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            z = z + 2 * this.get('hoeheMasskette') + 2 * this.get('abstandVomBauteil') - this.get('abstandVonDerMasslinie');
          } else {
            y = y - 2 * this.get('hoeheMasskette') - 2 * this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          }

          break;

        case 'spaltmass1':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z = -this.get('spaltmass') / 2 - this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x = this.get('traegerlaenge') / 2;
            }

            x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          } else {
            y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          }

          break;

        case 'spaltmass2':
          ueberstand = Number(bauteile.findBy('id', "auflager").get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
          x = this.get('auflagerlaenge') / 2 + ueberstand > this.get('x') / 2 ? this.get('auflagerlaenge') / 2 + ueberstand : this.get('x') / 2;
          y = this.get('traegerhoehe') + 0.1 > this.get('y') ? this.get('traegerhoehe') + 0.1 : this.get('y');
          z = this.get('spaltmass') / 2 + this.get('traegerbreite') / 2;

          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            if (x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === false) {
              x = this.get('traegerlaenge') / 2;
            }

            x = x + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          } else {
            y = y + this.get('hoeheMasskette') + this.get('abstandVomBauteil') + this.get('abstandVonDerMasslinie');
          }

          break;
      }

      return x + ' ' + y + ' ' + z;
    }),
    gabelTextRotation: Ember.computed('model.firstObject.transformHelper', function () {
      var typ = this.get('typ');
      var values;
      var x3d = this.get('model').objectAt(0);

      switch (typ) {
        case 'hoehe':
          if (x3d.get('bauteile').findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
            values = '-1 1 1 ' + this.winkelRadinat(240);
          } else {
            values = '0 0 1 ' + -this.winkelRadinat(90);
          }

          break;

        case 'breite1':
          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            values = '1 1 1 ' + this.winkelRadinat(240);
          } else {
            values = '0 1 0 ' + -this.winkelRadinat(90);
          }

          break;

        case 'breite2':
          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            values = '1 1 1 ' + this.winkelRadinat(240);
          } else {
            values = '0 1 0 ' + -this.winkelRadinat(90);
          }

          break;

        case 'laenge':
          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            values = '1 0 0 ' + -this.winkelRadinat(90);
          } else {
            values = '0 0 0 0';
          }

          break;

        case 'spaltmass1':
          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            values = '1 1 1 ' + this.winkelRadinat(240);
          } else {
            values = '0 1 0 ' + -this.winkelRadinat(90);
          }

          break;

        case 'spaltmass2':
          if (x3d.get('bauteile').findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            values = '1 1 1 ' + this.winkelRadinat(240);
          } else {
            values = '0 1 0 ' + -this.winkelRadinat(90);
          }

          break;
      }

      return values;
    }),
    gabelText: Ember.computed('model.firstObject.transformHelper', function () {
      var typ = this.get('typ');
      var value;

      switch (typ) {
        case 'hoehe':
          value = this.get('y');
          break;

        case 'breite1':
          value = this.get('z');
          break;

        case 'breite2':
          value = this.get('z');
          break;

        case 'laenge':
          value = this.get('x');
          break;

        case 'spaltmass1':
          value = this.get('spaltmass');
          break;

        case 'spaltmass2':
          value = this.get('spaltmass');
          break;
      }

      return (value * 10).toFixed(1);
    }),
    gabelColor: Ember.computed('model.firstObject.{hGabellagerAktiv,lGabellagerAktiv,bGabellagerAktiv,aGabellagerAktiv}', function () {
      var x3d = this.get('model').objectAt(0);
      var typ = this.get('typ');
      var value;

      switch (typ) {
        case 'hoehe':
          if (x3d.get('hGabellagerAktiv') === true) {
            value = '1 0 0';
          } else {
            value = '0 0 0';
          }

          break;

        case 'breite1':
          if (x3d.get('bGabellagerAktiv') === true) {
            value = '1 0 0';
          } else {
            value = '0 0 0';
          }

          break;

        case 'breite2':
          if (x3d.get('bGabellagerAktiv') === true) {
            value = '1 0 0';
          } else {
            value = '0 0 0';
          }

          break;

        case 'laenge':
          if (x3d.get('lGabellagerAktiv') === true) {
            value = '1 0 0';
          } else {
            value = '0 0 0';
          }

          break;

        case 'spaltmass1':
          if (x3d.get('aGabellagerAktiv') === true) {
            value = '1 0 0';
          } else {
            value = '0 0 0';
          }

          break;

        case 'spaltmass2':
          if (x3d.get('aGabellagerAktiv') === true) {
            value = '1 0 0';
          } else {
            value = '0 0 0';
          }

          break;
      }

      return value;
    }),
    wertEingetragen: Ember.computed('model.firstObject.{hGabellagerEingetragen,lGabellagerEingetragen,bGabellagerEingetragen,aGabellagerEingetragen}', function () {
      var typ = this.get('typ');
      var value;

      switch (typ) {
        case 'hoehe':
          value = this.get('model').objectAt(0).get('hGabellagerEingetragen');
          break;

        case 'breite1':
          value = this.get('model').objectAt(0).get('bGabellagerEingetragen');
          break;

        case 'breite2':
          value = this.get('model').objectAt(0).get('bGabellagerEingetragen');
          break;

        case 'laenge':
          value = this.get('model').objectAt(0).get('lGabellagerEingetragen');
          break;

        case 'spaltmass1':
          value = this.get('model').objectAt(0).get('aGabellagerEingetragen');
          break;

        case 'spaltmass2':
          value = this.get('model').objectAt(0).get('aGabellagerEingetragen');
          break;
      }

      return value;
    }),
    rendern: Ember.computed('model.firstObject.lastViewpoint', function () {
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = true;

      switch (typ) {
        case 'hoehe':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
            value = false;
          }

          break;

        case 'breite1':
          if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
            value = false;
          }

          break;

        case 'breite2':
          if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
            value = false;
          }

          break;

        case 'laenge':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
            value = false;
          }

          break;

        case 'spaltmass1':
          if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
            value = false;
          }

          break;

        case 'spaltmass2':
          if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
            value = false;
          }

          break;
      }

      return value;
    }),
    getMassketteKoordinaten: function (punkt1, punkt2, drehachsen, drehwinkel) {
      var skalierungsfaktor = 1;
      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;
      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;
      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;
      dp = this.drehungXAchse({
        x: 0,
        y: b6,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      dp = this.drehungXAchse({
        x: b1,
        y: b5,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      dp = this.drehungXAchse({
        x: -b1,
        y: b5,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      dp = this.drehungXAchse({
        x: -b05,
        y: b45,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      dp = this.drehungXAchse({
        x: b05,
        y: b55,
        z: 0
      }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    drehungXAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungYAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },
    drehungZAchse: function (v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    }
  });
  var _default = Transform;
  _exports.default = _default;
});