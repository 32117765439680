define("m08-2020/components/faserrichtun-g", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Transform = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'components/indexedlinese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    translation: Ember.computed('model.firstObject.{transformHelper,viewpointHelper}', function () {
      let x = 0;
      let y = 0;
      let z = 0;
      let cords = x + ' ' + y + ' ' + z;
      return cords;
    }),
    rotation: Ember.computed('model.firstObject.{transformHelper,viewpointHelper}', function () {
      let values = '0 0 0 0';
      return values;
    }),
    koordinaten: Ember.computed('model.firstObject.{transformHelper,viewpointHelper,vWinkel,hWinkel,istHt,skalierungsfaktor,schraubenGekreuzt}', function () {
      const name = this.get('name');
      const traeger = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      const punkte = traeger.get('punkt');
      const dicke = traeger.get('platte').objectAt(0).get('thickness');
      const x2 = punkte.findBy('id', name + 'p2').get('x');
      const x5 = punkte.findBy('id', name + 'p5').get('x');
      const xMFrp = x2 + (x5 - x2) / 2;
      const y = punkte.findBy('id', name + 'p5').get('y') / 2;
      const z = dicke;
      const delta = 0.5;
      const cords = xMFrp - 2 + delta + ' ' + (y + delta) + ' ' + z + ', ' + (xMFrp - 2 - delta) + ' ' + y + ' ' + z + ', ' + (xMFrp + 2 + delta) + ' ' + y + ' ' + z + ', ' + (xMFrp + 2 - delta) + ' ' + (y - delta) + ' ' + z;
      return cords;
    }),
    koordinatenIndex: Ember.computed('model.firstObject.{transformHelper,viewpointHelper}', function () {
      let values = "0 1 -1 1 2 -1 2 3 -1";
      return values;
    }),
    emissivecolor: Ember.computed('model.firstObject.{htbreiteAktiv,hthoeheAktiv,abreiteAktiv,alaengeAktiv,spabstandAktiv,bemessungslastAktiv,sicherheitsAbstandAktiv,ueberstandAktiv,spaltmassAktiv}', function () {
      let emsvcolor = '0 0 0';
      return emsvcolor;
    })
  });
  var _default = Transform;
  _exports.default = _default;
});